<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :disabled="isAddRecordActive"
            @click="navigateToKpiConfiguration(0)"
          >
            <span> {{ $t('ADD_KPI') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>
        <!-- Category-->
        <b-col
          cols="12"
          md="4"
        >
          <div class="d-flex align-items-center justify-content-end">
            <v-select
              v-model="templateCategory"
              :options="allCategories"
              label="name"
              :clearable="false"
              class="flex-grow-1 ml-50 mr-1"
              :placeholder="$t('SELECT_CATEGORY')"
              @input="fetchTemplates(templateCategory)"
            />
          </div>
        </b-col>
        <!-- Template-->
        <b-col
          cols="12"
          md="4"
        >
          <div class="d-flex align-items-center justify-content-end">
            <v-select
              v-model="template"
              :options="templates"
              label="name"
              :clearable="false"
              class="flex-grow-1 ml-50 mr-1"
              :placeholder="$t('SELECT_TEMPLATE')"
              @input="fetchTemplateKpi(template)"
            />
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refTemplateListTable"
      :items="templateKpis"
      responsive
      :fields="templatesKpiTableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: Name -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              variant="primary"
              :text="avatarText(data.item.name.substring(0,1))"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="navigateToKpiConfiguration(data.item.ID)"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-muted">{{ strSubstring(data.item.kpi_typ,10) }}</small>
        </b-media>
      </template>

      <!-- Column:unit -->
      <template #cell(Unit)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.unit }}
          </span>
        </b-media>
      </template>
      <!-- Column:str1 -->
      <template #cell(Description)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ strSubstring(data.item.str1,10) }}
          </span>
        </b-media>
      </template>
      <!-- Column:str2 -->
      <template #cell(ShortDescription)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ strSubstring(data.item.str2,10) }}
          </span>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`game-row-${data.item.ID}-send-icon`"
            v-b-modal.kpi-modal
            icon="EditIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="navigateToKpiConfiguration(data.item.ID)"
          />

          <feather-icon
            :id="`game-row-${data.item.mediaID}-preview-icon`"
            icon="Trash2Icon"
            size="16"
            class="cursor-pointer"
            @click="removeTemplateKpi(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalKpi"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BLink,
  BRow, BCol, BButton, BTable,
  BMedia,
  BPagination, VBPopover, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import { ref, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { mixinList } from '@/constants/mixinValidations'
import constants from '@/constants/static.json'
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BPagination,
    BLink,
    vSelect,
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [mixinList],
  data() {
    return {
      viewKpi: {
        ID: 0,
        template_id: 0,
        category_id: 0,
        name: '',
        str1: '',
        str2: '',
        rc1: 0,
        rc2: 0,
        userprompt1: '',
        userprompt2: '',
        value_type: '',
        unit: '',
      },
      isAddRecordActive: true,
      templateCategory: {
        ID: 0,
        name: 'Select Category',
        color: '',
        description: '',
      },
      templates: [],
      template: '',
      templateKpis: [],
    }
  },
  setup() {
    // Table Handlers
    const templatesKpiTableColumns = [
      { key: 'Name', sortable: false },
      { key: 'Unit', sortable: false },
      { key: 'Description', sortable: false },
      { key: 'ShortDescription', sortable: false },
      { key: 'Actions' },
    ]

    const { PER_PAGE_OPTIONS, PER_PAGE } = constants
    const refTemplateListTable = ref(null)

    const perPage = ref(PER_PAGE)
    const totalKpi = ref(0)
    const currentPage = ref(1)
    const perPageOptions = PER_PAGE_OPTIONS
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref('')

    const dataMeta = computed(() => {
      const localItemsCount = refTemplateListTable.value ? refTemplateListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalKpi.value,
      }
    })

    const refetchData = () => {
      refTemplateListTable.value.refresh()
    }

    const {
      successMessage, errorMessage, showErrorMessage,
    } = mixinAlert()

    return {
      templatesKpiTableColumns,
      perPage,
      currentPage,
      totalKpi,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTemplateListTable,
      statusFilter,
      refetchData,
      successMessage,
      errorMessage,
      showErrorMessage,
      avatarText,
    }
  },
  computed: {
    ...mapState('template', ['allCategories', 'allTemplateKpi']),
  },
  watch: {
    currentPage: {
      handler() {
        this.fetchTemplateKpi(this.template.ID)
      },
    },
  },
  created() {
    this.fetchAllCategories()
  },
  methods: {
    ...mapActions('template', ['fetchAllCategories', 'fetchAllTemplates', 'fetchAllTemplateKpi', 'deleteTemplateKpi']),
    removeTemplateKpi(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.name}  ${this.$i18n.t('KPI')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.onDeleteTemplateKpi(data)
          }
        })
    },
    navigateToKpiConfiguration(data) {
      const id = data
      const categoryId = this.templateCategory.ID
      const templateId = this.template.ID
      this.$router.push({
        name: 'template-kpi-configuration-id',
        params: { id },
        query: { templateId, categoryId },
      })
    },
    onDeleteTemplateKpi(data) {
      if (data) {
        this.deleteTemplateKpi(data.ID).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.KPI_DELETED'))
            this.fetchTemplateKpi(this.template)
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
    resolveLanguage(data) {
      const language = constants.LANGUAGES.find(i => i.country_code === data)
      if (language) {
        return language.name
      }
      return ''
    },
    fetchTemplates(e) {
      this.templates = []
      this.templateKpis = []
      this.isAddRecordActive = true
      const params = `?category_id=${e.ID}&type=game`
      this.fetchAllTemplates(params).then(response => {
        if (response) {
          this.templates = response
          this.template = ''
        }
      }).catch(() => {
        this.showErrorMessage()
      })
    },
    fetchTemplateKpi(e) {
      this.templateKpis = []
      try {
        this.fetchAllTemplateKpi(e.ID).then(() => {
          this.totalKpi = this.allTemplateKpi.count
          this.templateKpis = this.allTemplateKpi.data
          this.isAddRecordActive = false
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
